// import external dependencies
import 'jquery';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import singleProduct from './routes/singleProduct';
import taxProductCat from './routes/taxProductCat';
import favourites from './routes/favourites';

/** Populate Router instance with DOM routes */
const routes = new Router({
	common,
	home,
	aboutUs,
	singleProduct,
	taxProductCat,
	favourites,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
