export default {
	init() {

		// function display_favourites() {
		// 	var favourites = JSON.parse(window.localStorage.getItem('favourites'));

		// 	var data = {
		// 		action: 'load_favourites',
		// 		favourites: JSON.stringify(favourites),
		// 	}

		// 	$.ajax({
		// 		/* global sbiajaxurl */
		// 		url: sbiajaxurl,
		// 		type: 'post',
		// 		data: data,
		// 		success: function (favourites) {
		// 			$('#favourites').html(favourites);
		// 		},
		// 		error: function (errorThrown) {
		// 			console.log(errorThrown.responseText);
		// 		},
		// 	});
		// }

		// display_favourites();

		// function remove_move_from_favourites(product, pid) {
		// 	product.addClass('removing');
		// 	var favourites = JSON.parse(window.localStorage.getItem('favourites'));

		// 	if (favourites == null) {
		// 		alert('You dont have any favourites yet... :(');
		// 	} else {

		// 		// loop through favourites
		// 		for (var i = 0; i < favourites.length; i++) {
		// 			// if we find match to product id
		// 			if (favourites[i] === pid) {
		// 				// remove it from the favourites
		// 				favourites.splice(i, 1);
		// 			}
		// 		}

		// 		window.localStorage.setItem('favourites', JSON.stringify(favourites));
		// 		product.slideUp(300);
		// 		display_favourites();
		// 	}
		// }

		// $('body').on('click', '.remove-from-favs', function () {
		// 	remove_move_from_favourites(
		// 		$(this).closest('.product'),
		// 		$(this).data('pid')
		// 	);
		// });

	},
};
