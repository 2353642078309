import 'slick-carousel';
import { open_mini_cart } from './common.js'

export default {
	init() {


		if($('.woocommerce-product-gallery__image').length > 1) {
			$('.woocommerce-product-gallery__image').each(function(i, img){
				console.log(i);
				if(i > 0) {
					var the_image = $(img).find('img');
					var full_size_img = $(the_image).data('large_image');
					the_image.attr('src', full_size_img);
				}
			});
		}
		

		// $('.woocommerce-product-gallery__wrapper').slick({
		// 	slide: '.woocommerce-product-gallery__image',
		// 	rows: 0,
		// 	dots: true,
		// 	nextArrow: next_arrow,
		// 	prevArrow: prev_arrow,
		// });

		// var $warp_fragment_refresh = {
		// 	url: wc_cart_fragments_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'get_refreshed_fragments' ),
		// 	type: 'POST',
		// 	success: function( data ) {
		// 		if ( data && data.fragments ) {

		// 			$.each( data.fragments, function( key, value ) {
		// 				$( key ).replaceWith( value );
		// 			});

		// 			$( document.body ).trigger( 'wc_fragments_refreshed' );
		// 		}
		// 	}
		// };

		$('.entry-summary form.cart').on('submit', function (e) {
			e.preventDefault();

			// add overlay to product page stuff so cant resubmit etc
			$('.entry-summary').block({
				message: null,
				overlayCSS: {
					cursor: 'none',
				},
			});


			// get the product url and form data
			var product_url = window.location,
				form = $(this);


			// submit the data viw POST 
			$.post(product_url, form.serialize() + '&_wp_http_referer=' + product_url, function (result) {

				// get updated mini cart and basket count from the returned data (which is html)
				var new_mini_cart = $('.widget_shopping_cart_content', result);
				var new_cart_count = $('#cart-count', result);

				// replace the mini cart and count with this updated info
				$('.widget_shopping_cart_content').replaceWith(new_mini_cart);
				$('#cart-count').replaceWith(new_cart_count);

				//refresh WC fragments.
				// $.ajax($warp_fragment_refresh);

				$(document.body).trigger('wc_fragments_refreshed');

				// remove over lay from product
				$('.entry-summary').unblock();

				// open the minicart
				open_mini_cart();
			});
		});		
	},
};
