import 'slick-carousel';
import 'jquery.marquee';
import { next_arrow } from './common.js';
import { prev_arrow } from './common.js';

export default {
	init() {
		$('.banner-5050__item--left').on('init', function () {
			$('.banner-5050').css('background-image', 'none');
		});

		$('.banner-5050__item--left').slick({
			dots: false,
			arrows: false,
			infinite: false,
			fade: true,
			rows: 0,
			slide: '.item',
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 2000,
			pauseOnHover: false,
		});

		$('.banner-5050__item--right').slick({
			dots: false,
			arrows: false,
			infinite: false,
			fade: true,
			rows: 0,
			slide: '.item',
			autoplay: true,
			autoplaySpeed: 6000,
			speed: 2000,
			pauseOnHover: false,
		});

		$('.banner__fullwidth--slider').on('init', function () {
			$('.banner__fullwidth').css('background-image', 'none');
		});

		$('.banner__fullwidth--slider').slick({
			dots: false,
			arrows: false,
			infinite: false,
			fade: true,
			rows: 0,
			slide: '.item',
			autoplay: true,
			autoplaySpeed: 6000,
			speed: 2000,
			pauseOnHover: false,
		});

		$('.brands-marquee').marquee({
			duration: 35000,
			gap: 0,
			delayBeforeStart: 0,
			direction: 'left',
			duplicated: true,
			pauseOnHover: true,
			startVisible: true,
		});

		const new_arrivals_args = {
			slidesToShow: 4,
			slidesToScroll: 2,
			infinite: false,
			rows: 0,
			slide: '.product',
			nextArrow: next_arrow,
			prevArrow: prev_arrow,
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 900,
					settings: 'unslick',
				},
			],
		};

		const best_sellers_args = {
			slidesToShow: 2,
			slidesToScroll: 2,
			infinite: false,
			nextArrow: next_arrow,
			prevArrow: prev_arrow,
			rows: 0,
			slide: '.product',
			appendArrows: $('.you-may-like .best-sellers'),
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 900,
					settings: 'unslick',
				},
			],
		};

		// init sliders on load
		$('.homepage-new-arrivals .products').slick(new_arrivals_args);
		$('.you-may-like .best-sellers .products').slick(best_sellers_args);

		// Reinit the slick sliders if window size changed from below 900
		$(window).on('resize', function () {
			if ($(this).width() >= 900) {
				if (
					!$('.homepage-new-arrivals .products').hasClass(
						'slick-initialized'
					)
				) {
					$('.homepage-new-arrivals .products').slick(
						new_arrivals_args
					);
				}
				if (
					!$('.you-may-like .best-sellers .products').hasClass(
						'slick-initialized'
					)
				) {
					$('.you-may-like .best-sellers .products').slick(
						best_sellers_args
					);
				}
			}
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
