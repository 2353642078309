import 'jquery-sticky';
import AOS from 'aos';

export function open_mini_cart() {
	close_side_nav();
	$('#mini-cart').addClass('active');
	$('body').addClass('side-nav-active');
	$('#site-overlay').fadeIn(200);
}

export function close_side_nav() {
	$('#side-nav').removeClass('active');
	$('body').removeClass('side-nav-active');
	$('#site-overlay').fadeOut(200);
}

export const next_arrow =
	'<button type="button" class="slick-next"><svg viewBox="0 0 12 22" xmlns="http://www.w3.org/2000/svg"> <path d="M2.272.384C1.728-.128.896-.128.384.384s-.512 1.344 0 1.888L8.8 10.688.384 19.105a1.353 1.353 0 000 1.888c.512.512 1.344.512 1.888 0l9.344-9.377c.512-.512.512-1.344 0-1.888L2.272.384z" fill="#000" fill-rule="nonzero"/></svg></button>';
export const prev_arrow =
	'<button type="button" class="slick-prev"><svg viewBox="0 0 12 22" xmlns="http://www.w3.org/2000/svg"> <path d="M.384 9.728L9.728.384c.544-.512 1.376-.512 1.888 0s.512 1.344 0 1.888L3.2 10.688l8.416 8.417a1.353 1.353 0 010 1.888c-.512.512-1.344.512-1.888 0L.384 11.616c-.512-.512-.512-1.344 0-1.888z" fill="#000" fill-rule="nonzero"/></svg></button>';

export default {
	init() {
		if ($('body').hasClass('tax-product_cat')) {
			$('#product-sorting').sticky({
				topSpacing: 0,
				zIndex: 100,
			});
		} else {
			$('#site-nav').sticky({
				topSpacing: 0,
				zIndex: 100,
			});
		}

		$('.nav-woo .search button').on('click', function () {
			$('.desktop-search').fadeToggle(200);
			$(
				'.dgwt-wcas-sf-wrapp input[type=search].dgwt-wcas-search-input'
			).focus();
		});

		/*
		 *	SIDE BAR NAVIGATION & MINI CART
		 */

		function close_side_nav() {
			$('#side-nav').removeClass('active');
			$('body').removeClass('side-nav-active');
			$('#site-overlay').fadeOut(200);
		}

		function close_mini_cart() {
			$('#mini-cart').removeClass('active');
			$('body').removeClass('side-nav-active');
			$('#site-overlay').fadeOut(200);
		}

		function open_side_nav() {
			close_mini_cart();
			$('#side-nav').addClass('active');
			$('body').addClass('side-nav-active');
			$('#site-overlay').fadeIn(200);
		}

		// function open_mini_cart() {
		// 	close_side_nav();
		// 	$('#mini-cart').addClass('active');
		// 	$('body').addClass('side-nav-active');
		// 	$('#site-overlay').fadeIn(200);
		// }

		// function close_mini_cart() {
		// 	$('#mini-cart').removeClass('active');
		// 	$('body').removeClass('side-nav-active');
		// 	$('#site-overlay').fadeOut(200);
		// }

		$('.side-nav-toggle.open').on('click', open_side_nav);
		$('.side-nav-toggle.close').on('click', close_side_nav);

		$('.mini-cart-toggle.open').on('click', open_mini_cart);
		$('.mini-cart-toggle.close').on('click', close_mini_cart);

		$('#site-overlay').on('click', function () {
			close_side_nav();
			close_mini_cart();
			close_filters();
		});

		function open_filters() {
			close_side_nav();
			$('#product-filters').addClass('active');
			$('body').addClass('side-nav-active');
			$('#site-overlay').fadeIn(200);
		}

		function close_filters() {
			$('#product-filters').removeClass('active');
			$('body').removeClass('side-nav-active');
			$('#site-overlay').fadeOut(200);
		}

		$('.filters-toggle.open').on('click', open_filters);
		$('.filters-toggle.close').on('click', close_filters);

		$(document).on('keydown', function (event) {
			if (event.key == 'Escape') {
				close_side_nav();
				close_mini_cart();
			}
		});

		$('.nav-accordions__item .title').on('click', function () {
			$(this).next('.items').slideToggle(200);
			$(this).toggleClass('active');
		});

		$('#product-filters h3').on('click', function () {
			$(this).next('ul').slideToggle(200);
			$(this).toggleClass('active');
		});

		$('#product-filters .filter-item').each(function (i, el) {
			console.log(el);
			var filter = $(el);

			if ($('ul', filter).find('li.chosen').length != 0) {
				filter.addClass('has-active-filter');
			}
		});

		// var $warp_fragment_refresh2 = {
		// 	url: wc_cart_fragments_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'get_refreshed_fragments' ),
		// 	type: 'POST',
		// 	success: function( data ) {
		// 		if ( data && data.fragments ) {

		// 			$.each( data.fragments, function( key, value ) {
		// 				$( key ).replaceWith( value );
		// 			});

		// 			$( document.body ).trigger( 'wc_fragments_refreshed' );
		// 		}
		// 	}
		// };

		/*global wc_add_to_cart_params */

		// Ajax delete product in the cart
		$(document).on('click', '.mini_cart_item a.remove', function (e) {
			e.preventDefault();

			var product_id = $(this).attr('data-product_id'),
				cart_item_key = $(this).attr('data-cart_item_key'),
				product_container = $(this).parents('.mini_cart_item');

			product_container.block({
				message: null,
				overlayCSS: {
					cursor: 'none',
				},
			});

			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: wc_add_to_cart_params.ajax_url,
				data: {
					action: 'product_remove',
					product_id: product_id,
					cart_item_key: cart_item_key,
				},
				success: function (response) {
					if (!response || response.error) return;

					// var this_page = window.location.toString();
					// var cart_hash = response.cart_hash;
					var fragments = response.fragments;
					var cart_count = response.cart_count;

					// // Replace fragments
					// if ( fragments ) {
					// 	$.each( fragments, function( key, value ) {
					// 		$( key ).replaceWith( value );
					// 	});
					// }
					$('.widget_shopping_cart_content').replaceWith(fragments);
					$('#cart-count').replaceWith(cart_count);
					console.log(response);
					// Update cart
					// $.ajax($warp_fragment_refresh2);

					product_container.unblock();
				},
			});
		});

		$('.usps-list').slick({
			dots: false,
			arrows: false,
			infinite: false,
			rows: 0,
			slide: '.usps-list__item',
			slidesToShow: 5,
			nextArrow: next_arrow,
			prevArrow: prev_arrow,
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 3,
						arrows: true,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						arrows: true,
					},
				},
				{
					breakpoint: 400,
					settings: {
						slidesToShow: 1,
						arrows: true,
					},
				},
			],
		});

		if (sessionStorage.grid_view) {
			$('ul.products').addClass(sessionStorage.grid_view);
			$('input[value="' + sessionStorage.grid_view + '"]').prop(
				'checked',
				true
			);
		} else {
			$('input[value="grid_4"]').prop('checked', true);
		}

		$('input[name="grid_view"]').on('change', function () {
			var grid_view = $('input[name="grid_view"]:checked').val();
			$('ul.products').removeClass('grid_4 grid_2');
			$('ul.products').addClass(grid_view);
			sessionStorage.setItem('grid_view', grid_view);
		});

		// function add_to_favourites(pid){

		// 	$.ajax({
		// 		/* global sbiajaxurl */
		// 		url: sbiajaxurl,
		// 		type: 'post',
		// 		data: {
		// 			action: 'add_to_favourites',
		// 			pid: pid,
		// 		},
		// 		success: function (data) {
		// 			// alert('added!');
		// 			console.log(data.response);
		// 		},
		// 		error: function (errorThrown) {
		// 			console.log(errorThrown);
		// 		},
		// 	});
		// }

		// function retrieve_cookie(name) {
		// 	var cookie_value = '',
		// 		current_cookie = '',
		// 		name_expr = name + '=',
		// 		all_cookies = document.cookie.split(';'),
		// 		n = all_cookies.length;

		// 	for (var i = 0; i < n; i++) {
		// 		current_cookie = all_cookies[i].trim();
		// 		if (current_cookie.indexOf(name_expr) == 0) {
		// 			cookie_value = current_cookie.substring(name_expr.length, current_cookie.length);
		// 			break;
		// 		}
		// 	}
		// 	return cookie_value;
		// }

		// $('.add-to-favs').on('click', function () {
		// 	add_to_favourites($(this).data('pid'));
		// 	// var existing_favourites = document.cookie
		// 	// var cookie_name = 'favourites';
		// 	// var res = retrieve_cookie(cookie_name);
		// 	// if (res) {
		// 	// 	res = JSON.parse(res);
		// 	// 	console.log(res);
		// 	// 	// alert('Cookie with name "' + cookie_name + '" value is ' + '"' + res + '"');
		// 	// } else {
		// 	// 	// res = JSON.parse(res);
		// 	// 	alert('Cookie with name "' + cookie_name + '" does not exist...');
		// 	// }
		// });

		$('.global-reviews .reviews-slick').slick({
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			infinite: false,
			dots: true,
			responsive: [
				{
					breakpoint: 960,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		$('.popout .toggle').on('click', function () {
			$(this).parent().toggleClass('popout__active');
		});

		var x, i, j, l, ll, selElmnt, a, b, c;
		/* Look for any elements with the class "custom-select": */
		x = document.getElementsByClassName('custom-select');
		l = x.length;
		for (i = 0; i < l; i++) {
			selElmnt = x[i].getElementsByTagName('select')[0];
			ll = selElmnt.length;
			/* For each element, create a new DIV that will act as the selected item: */
			a = document.createElement('DIV');
			a.setAttribute('class', 'select-selected');
			a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
			x[i].appendChild(a);
			/* For each element, create a new DIV that will contain the option list: */
			b = document.createElement('DIV');
			b.setAttribute('class', 'select-items select-hide');
			for (j = 1; j < ll; j++) {
				/* For each option in the original select element,
				create a new DIV that will act as an option item: */
				c = document.createElement('DIV');
				c.innerHTML = selElmnt.options[j].innerHTML;
				c.addEventListener('click', function () {
					/* When an item is clicked, update the original select box,
					and the selected item: */
					var y, i, k, s, h, sl, yl;
					s =
						this.parentNode.parentNode.getElementsByTagName(
							'select'
						)[0];
					sl = s.length;
					h = this.parentNode.previousSibling;
					for (i = 0; i < sl; i++) {
						if (s.options[i].innerHTML == this.innerHTML) {
							s.selectedIndex = i;
							h.innerHTML = this.innerHTML;
							y =
								this.parentNode.getElementsByClassName(
									'same-as-selected'
								);
							yl = y.length;
							for (k = 0; k < yl; k++) {
								y[k].removeAttribute('class');
							}
							this.setAttribute('class', 'same-as-selected');
							break;
						}
					}
					h.click();
					$('form.woocommerce-ordering').trigger('submit');
				});
				b.appendChild(c);
			}
			x[i].appendChild(b);
			a.addEventListener('click', function (e) {
				/* When the select box is clicked, close any other select boxes,
				and open/close the current select box: */
				e.stopPropagation();
				closeAllSelect(this);
				this.nextSibling.classList.toggle('select-hide');
				this.classList.toggle('select-arrow-active');
			});
		}

		function closeAllSelect(elmnt) {
			/* A function that will close all select boxes in the document,
			except the current select box: */
			var x,
				y,
				i,
				xl,
				yl,
				arrNo = [];
			x = document.getElementsByClassName('select-items');
			y = document.getElementsByClassName('select-selected');
			xl = x.length;
			yl = y.length;
			for (i = 0; i < yl; i++) {
				if (elmnt == y[i]) {
					arrNo.push(i);
				} else {
					y[i].classList.remove('select-arrow-active');
				}
			}
			for (i = 0; i < xl; i++) {
				if (arrNo.indexOf(i)) {
					x[i].classList.add('select-hide');
				}
			}
		}

		/* If the user clicks anywhere outside the select box,
		then close all select boxes: */
		document.addEventListener('click', closeAllSelect);
	},
	finalize() {
		AOS.init();
	},
};
